[type='text'], input:where(:not([type])), [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  background-color: hsl(var(--background));
}

.p-datatable .p-datatable-tbody > tr:last-child > td {
  border-width: 0;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-tabview .p-tabview-nav {
  background: transparent;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
  border-color: hsl(var(--primary));
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-dropdown-item.p-ripple {
  white-space: normal;
}

.button-w-full button {
  width: 100%;
}

[type='text']:focus {
  box-shadow: none;
  border-color: none;
}

.p-chips .p-chips-multiple-container {
  width: 100%;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.05);
  transition-duration: 0.2s;
}

.p-icon-spin {
  color: hsl(var(--primary));
  width: 2rem;
  height: 2rem;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid hsl(var(--border));
  border-width: 1px;
}

.p-datatable-wrapper {
  border: solid hsl(var(--border));
  border-width: 1px;
  border-radius: 0.5rem;
}

textarea {
  background-color: hsl(var(--background));
  border-color: hsl(var(--border));
}

// To be used with any single column that is fixed to the right of a primeNgTable
// This is to improve performance as calculations are not required
.frozen-right {
  position: sticky;
  right: 0;
  background-color: hsl(var(--background));
}

.p-chips-input-token > input {
  border: none;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-right: 0.5rem;
}
