@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 204 80% 16%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 217 91% 60%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 210 14.7% 26.7%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 208 14% 65%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 214 13% 32%;
    --input: 216 34% 17%;

    --primary: 217 91% 60%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 10.3% 22.7%;
    --secondary-foreground: 210 40% 98%;

    --accent: 214 13% 32%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }

  .dark-tmp {
    --background: 204 80% 16%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 208 14% 65%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 214.3 18.2% 30.2%;
    --input: 216 34% 17%;

    --primary: 217 91% 60%;
    --primary-foreground: 210 40% 98%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 202 80% 24%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

:root {
  font-size: 0.9rem;
}
